import React from "react"
import { Link } from "gatsby"

import Barre from "../components/barre"
import Footer from "../components/footer"
import Diapo from "../components/diapo"
import pdf from "../static/TarifsMarieeCreationPassion.pdf"

import "../css/style.css"
import "../css/home.css"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import "aos/dist/aos.css"
import AOS from "aos/dist/aos.js"

class Home extends React.Component {
  componentDidMount() {
    AOS.init()
  }


  render() {
  return <div>
    <GatsbySeo
      language='fr'
      title='Mariée Création Passion'
      description='Mariée Création Passion situé sur la Côte d Azur près de Cannes, crée et réalise vos modèles exclusifs sur mesures'
    />
    <Barre />
    <div class="Titre container" id="SurMesure">
      <div class="row ">
        <div class="col-12 col-xl-8 d-flex d-lg-block text-center text-xl-start">
          <h2 class="text-center">Des vêtements et accessoires de mariage sur mesure et personnalisés</h2>
          <p data-aos="fade-right" data-aos-duration="500" data-aos-delay="100">
            <mark>« <span>Mariée Création Passion</span> »</mark> situé sur la Côte d'Azur près de Cannes, crée et réalise vos modèles exclusifs:
            <b> accessoires et vêtements</b> de cortège <b>uniques et sur mesure</b> qui vous correspondent.<br />
            <b> Vos modèles exclusifs</b> que vous ne trouverez nulle part ailleurs!<br /><br />
          </p><p data-aos="fade-right" data-aos-duration="500" data-aos-delay="250" class="text-center p2">
            <u>Pour la Mariée</u>
            <br />
            Voile, étole, cape, boléro, jupon, aumonière... assortis et agrémentés de détails raffinés rappelant votre robe.
            Je propose également la personnalisation de vos accessoires de votre coiffure, vos gants...<br /><br />
          </p><p data-aos="fade-right" data-aos-duration="500" data-aos-delay="350" class="text-center p2">
            <u>Pour le Marié</u><br />
            Confection du gilet assorti à la lavallière, nœud papillon, cravate, pochette...<br /><br />
          </p><p data-aos="fade-right" data-aos-duration="500" data-aos-delay="400" class="text-center p2">
            <u>Pour le Cortège</u><br />

            Robes des demoiselles d'honneur dans la tonalité de la robe de mariée,
            gilet et accessoires des garçons d'honneur,
            <mark>« costumisation »</mark> de la tenue des invités selon le thème des mariés.
            <br /><br/>
            </p><p data-aos="fade-right" data-aos-duration="500" data-aos-delay="500" class="p2">
            <mark>« <span>Mariée Création Passion</span> »</mark> vous propose de créer et confectionner votre coussin porte-alliances.
            <br />assorti à la tenue et aux accessoires des mariés pour une parfaite unité, ou bien encore de personnaliser un coussin que vous possédez déjà.<br /><br />


            Sur demande,possibilité de confectionner les accessoires dans des étoffes de soies françaises et dentelles de Calais <mark>« haute-couture »</mark> pour plus de raffinement.
            Comme toute collection <mark>« <b>haut-de-gamme</b> »</mark>, les finitions de ses modèles sont<mark> <b>« cousues main »</b></mark>.<br />
            <br/>
            Commander à <mark>« <span>Mariée Création Passion</span> »</mark> , c'est un gage de <b>qualité</b>,
            avec tout le <mark>« savoir-faire »</mark> de l'<b>artisanat Français</b>.<br /><br />


            <mark>« <span>Mariée Création Passion</span> »</mark> s’adresse à vous, futurs mariés qui habitez les <b>Alpes-Maritimes (06)</b>, dans la localité de <b>Cannes</b>, Grasse, Mougins, Mandelieu ou Fréjus (Var) ou bien encore Monaco.
            <br />
            Pensez à demander votre <Link to="/cours/#Contact">devis gratuit</Link> , quelques mois avant votre mariage.

          </p>
        </div>
        <div class="col-4"><div class="ImgAc1"></div></div>
        {/* <Diapo /> */}
      </div>
    </div>
    <div class="Diaporama container-fluid">
      <div class="row mx-auto">
        <div  data-aos="fade-top" data-aos-duration="500" id="carouselExampleControls" class="carousel slide d-none d-md-block" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="d-block w-100 ImgD1" ></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgD2"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgD3"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgD4"></div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        {/* Carrousel Smartphone */}
        <div id="carouselControls" class="carousel slide d-block d-md-none" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="d-block w-100 ImgC ImgC1" ></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC2"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC3"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC4"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC5"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC6"></div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-100 ImgC ImgC7"></div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mx-auto">
        <Link to="/realisation"  data-aos="fade-right" data-aos-duration="500" data-aos-delay="100" class="col-lg-6 col-12 col-6 text-center">
          <button class="btnM col-lg-6 col-12">Voir plus de réalisation</button>
        </Link>
        <a href={pdf} target="_blank" rel="noreferrer" class="col-lg-6 col-12 text-center" data-aos="fade-right" data-aos-duration="500" >
        <button class="btnM col-lg-6 col-12">Prix</button>
        </a>
      </div>
    </div>


    <Footer />
  </div>
}
}
export default Home